/**
 * Electrical 电气图册配置文件
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Tooltip, Progress, Message, Spin, Modal,Select,Option,Poptip } from "view-design";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Tooltip', Tooltip);
Vue.component('Progress', Progress);
Vue.component('Spin', Spin);
Vue.component('Modal', Modal);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Poptip', Poptip);
import { mapMutations, mapState } from "vuex";
import { onSysncUploadFiles } from "@/utils/request-function";
import { queryFilesInfoService, systemUploadType } from "@/service/system-service";
import {
    getElectricalInfoService,
    editElectricalService,
    getDeviceInfoService,
    updateElectricStatusService,
    getStructuralIdService,
} from "@/service/device-info-service";
import { saveImage } from "@/utils/tools";
import ViewImage from '@/components/ViewImage/viewImage.vue'
const Electrical = {
    name:'Electrical',
    components:{ ViewImage },
    computed: {
        ...mapState({
            electricalId: state => state.system.electricalId, // 图册id
            deviceInfo: state => state.system.deviceInfo, //设备信息
        }),
    },
    data() {
        return {
            configLoading:false, //查询信息loading
            configNameVisible:false, //设置图册边框可显示
            imageLoading:false,
            speedSingleVisible:false, //单文件上传进度可显示
            configTabStatus:false, //标记状态
            relevanceBoxVisible:false, //关联状态可显示
            signSelectVisible:false, //选中的标记可显示
            selectBoxTabs:false,
            imageName:'',
            imageSrc:'', //上传缩略图
            viewImageVisible:false,
            complete:0, //上传进度
            drawing:false, //是否绘制
            imageWidth:0,
            imageHeight:0,
            imageLeft:0,
            imageTop:0,
            currentX:0, //记录落点的x坐标
            currentY:0, //记录落点的Y坐标
            imageRatio:0,
            minImageRatio:0,
            imageRatioInitial:0,
            signDrawing:false, //标记绘制状态
            signStatus:false, //框的关闭开启状态
            signWidth:0, //标记的宽度
            signHeight:0, //标记的高度
            signX:0,//标记落点x坐标
            signY:0,//标记落点y坐标
            signLeft:0, //标记左边距距离
            signTop:0,//标记上边距距离
            signBoxDrawing:false, //标记后绘制状态
            signBoxX:0,//标记绘制后落点x坐标
            signBoxY:0,//标记绘制后落点y坐标
            configParams:{
                configName:'',//设置图册名称
                configData:[], //图册数据集合
                fileUrl:'',//文件路径
                fileSign:'', //文件签名
                status:0, //发布状态
            },
            configArray:{
                file:'',
                fileName:'',
                fileUrl:'',
                partsCoordinate:[],
                status:0
            },//原数据存储
            configObj:{} ,
            tabArray:[], //结构部件数据集
            configSelectObj:{}, //当前选中的标记数据
        }
    },
    created() {
        if(this.electricalId !== ''){
            this.onElectricalInfo().then()
        }
    },
    methods: {
        ...mapMutations('system',['SET_CLASSIFY_MENU_ARRAY','SET_SELECTED_MENU_ID','SET_ELECTRICAL_ID']),
        /**
         * 返回
         */
        onBack() {
            this.$router.push('/device/info')
        },
        /**
         * 鼠标滚轮事件
         * @param e
         */
        onMousewheel(e) {
            if(this.configTabStatus){
                return
            }
            if(e.wheelDelta > 0){
                this.onImageEnlarge()
            }else{
                this.onImageNarrow()
            }
        },
        /**
         * 记录绘制后标记按下动作
         * @param e
         */
        onSignBoxMouseDown(e){
            this.signBoxDrawing = true
            this.signBoxX = e.offsetX
            this.signBoxY = e.offsetY
        },
        /**
         * 记录绘制后标记抬起动作
         */
        onSignBoxMouseUp(){
            this.signBoxDrawing = false
            this.configObj.x = this.signLeft
            this.configObj.y = this.signTop
            this.signBoxX = 0
            this.signBoxY = 0
        },
        /**
         * 记录绘制后标记移动动作
         * @param e
         */
        onSignBoxMouseMove(e){
            if (!this.signBoxDrawing) { return; }
            let imageWidth = this.imageWidth*(this.imageRatio/100)
            let imageHeight = this.imageHeight*(this.imageRatio/100)
            this.signLeft = ((e.clientX - 490-this.imageLeft-this.signBoxX)/imageWidth)*100
            this.signTop = ((e.clientY - 100-this.imageTop-this.signBoxY)/imageHeight)*100
        },
        /**
         * 记录标记按下动作
         * @param e
         */
        onSignMouseDown(e) {
            this.configSelectObj = {}
            this.configObj = {}
            this.signWidth = 0;
            this.signHeight = 0
            for(let i in this.configParams.configData){
                this.configParams.configData[i].signFixedStatus = true
            }
            this.signDrawing = true
            this.signSelectVisible = true
            this.signX = e.offsetX
            this.signY = e.offsetY
            this.signLeft = (this.signX/(this.imageWidth*(this.imageRatio/100))*100)
            this.signTop = (this.signY/(this.imageHeight*(this.imageRatio/100))*100)
        },
        /**
         * 记录标记抬起动作
         */
        onSignMouseUp() {
            if(this.signWidth === this.signHeight){
                this.signSelectVisible = false
            }
            if (!this.signDrawing) { return; }
            this.signDrawing = false;
            if(this.signWidth === 0 || this.signHeight === 0){
                return
            }
            this.relevanceBoxVisible = true
            this.configObj =
                {
                    signId:'',
                    x:this.signLeft,
                    y:this.signTop,
                    width:this.signWidth,
                    height:this.signHeight,
                    imageWidth:this.imageWidth,
                    imageHeight:this.imageHeight,
                    partsName:'',
                    partsId:'',
                    partsImageKey:'',
                    signFixedStatus:true,
                    signFixedInfoStatus:false,
                }
            this.configTabStatus = false
            // this.configParams.configData.push({
            //     x:this.signX,
            //     y:this.signY,
            //     width:this.signWidth,
            //     height:this.signHeight,
            //     imageWidth:this.imageWidth,
            //     imageHeight:this.imageHeight,
            //     signUnit:this.signUnit
            // })
        },
        /**
         * 清除标记
         */
        onSignClear(action) {
            this.signX = 0
            this.signY = 0;
            this.signWidth = 0;
            this.signHeight = 0
            this.signLeft = 0
            this.signTop = 0
            this.configTabStatus = true
            this.relevanceBoxVisible = false
            this.selectBoxTabs = false
            this.signSelectVisible = false
            if(action!=='add'){
                for(let i in this.configParams.configData){
                    if(this.configSelectObj.signId === this.configParams.configData[i].signId){
                        this.configParams.configData.splice(i,1)
                    }
                }
            }
            this.configObj = {}
            this.configSelectObj = {}
        },
        /**
         * 删除已选中的标记
         */
        onDeleteTabs() {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这个标记点吗？</p>',
                onOk: () => {
                    this.onSignClear()
                }
            });
        },
        /**
         * 修改标记结构部件
         */
        onEditTabs() {
            this.relevanceBoxVisible = true
            this.signX = this.configSelectObj.x
            this.signY = this.configSelectObj.y;
            this.signWidth = this.configSelectObj.width;
            this.signHeight = this.configSelectObj.height
            this.signLeft = this.configSelectObj.x
            this.signTop = this.configSelectObj.y
            this.onSignTab()
            // this.configSelectObj = {}
            this.configSelectObj.signFixedInfoStatus = false
            for(let i in this.configArray.partsCoordinate){
                if(this.configArray.partsCoordinate[i].signId === this.configSelectObj.signId){
                    this.configArray.partsCoordinate[i].signFixedInfoStatus = false
                }
            }
        },
        /**
         * 添加标记
         */
        onSignAdd() {
            if(this.configObj.partsId === ''){
                return
            }
            if(this.signLeft<0 || this.signTop<0 || this.signLeft+this.signWidth>100 || this.signTop+this.signHeight>100){
                Message.warning('请把标记框移动到图片内！')
                return
            }
            let status = false
            for(let i in this.configParams.configData){
                if(this.configParams.configData[i].signId === this.configObj.signId){
                    status = true
                }
            }
            if(!status){
                this.configParams.configData.push(this.configObj)
            }else{
                for(let i in this.configParams.configData){
                    if(this.configParams.configData[i].signId === this.configObj.signId){
                        this.configParams.configData[i].partsName = this.configObj.partsName
                        this.configParams.configData[i].partsImageKey = this.configObj.partsImageKey
                        this.configParams.configData[i].partsId = this.configObj.partsId
                        this.configParams.configData[i].signFixedStatus = true
                    }
                }
                for(let i in this.configArray.partsCoordinate){
                    if(this.configArray.partsCoordinate[i].signId === this.configSelectObj.signId){
                        this.configArray.partsCoordinate[i].signFixedStatus = true
                    }
                }
            }
            this.onSignClear('add')
        },
        /**
         * 记录标记移动动作
         * @param e
         */
        onSignMouseMove(e) {
            if (!this.signDrawing) { return; }
            this.signWidth = e.offsetX - this.signX
            this.signHeight = e.offsetY - this.signY
            this.signWidth = this.signWidth/(this.imageWidth*(this.imageRatio/100))*100
            this.signHeight = this.signHeight/(this.imageHeight*(this.imageRatio/100))*100
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            if(this.configTabStatus){
                return
            }
            this.drawing = true;
            this.currentX = e.offsetX
            this.currentY = e.offsetY
            this.signStatus = false
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp() {
            if(this.configTabStatus){return;}
            if (!this.drawing) { return; }
            this.drawing = false;
            if(!this.signStatus){
                this.configSelectObj.signFixedInfoStatus = false
                this.relevanceBoxVisible = false
                this.signSelectVisible = false
                for(let i in this.configParams.configData){
                    if(this.configSelectObj.signId === this.configParams.configData[i].signId){
                        this.configParams.configData[i].signFixedStatus = true
                    }
                }
                for(let i in this.configArray.partsCoordinate){
                    if(this.configSelectObj.signId === this.configArray.partsCoordinate[i].signId){
                        this.configArray.partsCoordinate[i].signFixedStatus = true
                    }
                }
                this.configObj = {}
            }
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if(this.configTabStatus){
                return
            }
            if (!this.drawing) { return; }
            this.imageLeft = (e.clientX - 490) - this.currentX
            this.imageTop = (e.clientY - 100) - this.currentY
            this.signStatus = true
        },
        /**
         * 图片放大比例
         */
        onImageEnlarge() {
            if((this.imageRatio + 25 ) > 400){
                this.imageRatio = 400
                this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
                this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
                Message.warning('已经是最大值')
                return
            }
            if((this.imageRatio+25)%25!==0){
                this.imageRatio = this.imageRatio+25 - (this.imageRatio+25)%25
            }else{
                this.imageRatio+=25
            }
            this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 图片缩小比例
         */
        onImageNarrow() {
            if(this.minImageRatio>=50){
                if(this.imageRatio - 25 <= 50){
                    this.imageRatio = 50
                    this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
                    this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
                    Message.warning('已经是最小值')
                    return
                }
            }else{
                if((this.imageRatio - 25 ) <= 0){
                    this.imageRatio = 10
                    this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
                    this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
                    Message.warning('已经是最小值')
                    return
                }
            }
            if(this.imageRatio%25!==0){
                this.imageRatio -= this.imageRatio % 25
            }else{
                this.imageRatio-=25
            }
            this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 适应画布
         */
        onImageInitial() {
            this.imageRatio = this.imageRatioInitial
            this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 图片初始化
         * @param src
         */
        onImageLoad(src){
            this.imageLoading = true
            let image = new Image();
            image.src = src
            image.onload = ()=>{
                this.imageLoading = false
                this.imageWidth = image.width
                this.imageHeight = image.height
                if(image.height < window.innerHeight - 100){
                    (window.innerHeight - 100)/image.height*100>400?this.imageRatio = 400 :this.imageRatio = (window.innerHeight - 100)/image.height*100
                }else{
                    Math.floor((window.innerHeight - 100)/image.height*100)<10?this.imageRatio = 10 : this.imageRatio = Math.floor((window.innerHeight - 100)/image.height*100)
                }
                this.minImageRatio = this.imageRatio
                this.imageRatioInitial = this.imageRatio
                this.imageLeft = ((window.innerWidth - 490) - this.imageWidth*(this.imageRatio/100))/2
                this.imageTop = ((window.innerHeight - 100)- this.imageHeight*(this.imageRatio/100))/2
            }
        },
        /**
         * 电气图册上传文件
         * @param event
         * @returns {Promise<void>}
         */
        async onConfigFileChange(event,update){
            const file =  event.target.files[0];
            this.fileType = file.type
            if (!['image/png', 'image/jpg','image/jpeg'].includes(file.type)) {
                Message.warning('请上传正确的图片')
                return
            }
            if (file.size > 5 * 1024 * 1024) {
                Message.warning('请选择5M以内的图片')
                return
            }
            this.configLoading = true
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.configLoading = false
                this.configParams.fileUrl = data[0].url
                this.configParams.fileSign = data[0].resourceName
                this.onImageLoad(data[0].url)
                this.complete = 0
                this.$forceUpdate()
                if(update === 'updateImage'){
                    this.configParams.configData = []
                    this.onSignClear()
                }
            }).catch(()=>{
                this.configLoading = false
                this.complete = 0
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 更新图册
         */
        onUpdateImage() {
            Modal.confirm({
                title: '换图后标记点将全部清除',
                content: '<p>您确定要操作吗？</p>',
                onOk: () => {
                    this.$refs.inputer.dispatchEvent(new MouseEvent('click'))
                }
            });
        },
        /**
         * 清除标记
         */
        onClearTab() {
            Modal.confirm({
                title: '确定要清除标记吗？',
                content: '<p>清除后不可撤销，配置的标记将被清空。</p>',
                onOk: () => {
                    this.configParams.configData = []
                    this.onSignClear()
                    this.onEditElectrical().then()
                }
            });
        },
        /**
         * 修改标记定位状态
         * @param obj
         */
        onSignFixed(obj) {
            this.configSelectObj = obj
            this.configObj = obj
            this.configTabStatus = false
            this.signSelectVisible = false
            for(let i in this.configParams.configData){
                if(this.configParams.configData[i].signId === obj.signId){
                    this.configParams.configData[i].signFixedStatus = false
                    this.configParams.configData[i].signFixedInfoStatus = true
                }else{
                    this.configParams.configData[i].signFixedStatus = true
                    this.configParams.configData[i].signFixedInfoStatus = false
                }
            }
            for(let i in this.configArray.partsCoordinate){
                if(this.configArray.partsCoordinate[i].signId === obj.signId){
                    this.configArray.partsCoordinate[i].signFixedStatus = false
                    this.configArray.partsCoordinate[i].signFixedInfoStatus = true
                }else{
                    this.configArray.partsCoordinate[i].signFixedStatus = true
                    this.configArray.partsCoordinate[i].signFixedInfoStatus = false
                }
            }
            this.relevanceBoxVisible = false
            this.onStructuralIdService(obj.partsId[obj.partsId.length-1]).then()
        },
        /**
         * 还原标记点定位状态
         */
        onClearSignFixedStatus() {
            for(let i in this.configParams.configData){
                this.configParams.configData[i].signFixedStatus = true
                this.configParams.configData[i].signFixedInfoStatus = false
            }
        },
        /**
         * 获取结构部件
         */
        onSignTab() {
            this.selectBoxTabs = true
            this.onTabList().then()
        },
        /**
         * 查看结构部件图片
         */
        onLookImage() {
            if(this.configSelectObj.partsImageKey === ''){
                Message.warning('此部件未添加图片！')
                return
            }
            this.onImageUrl(this.configSelectObj.partsImageKey).then()
        },
        /**
         * 选择结构部件
         */
        onSelectTab(obj) {
            this.configObj.partsName = obj.label
            this.configObj.partsId = obj.value
            for(let i in this.tabArray){
                if(this.tabArray[i].id === obj.value){
                    this.configObj.partsImageKey = this.tabArray[i].fileUrl
                }
            }
        },
        /**
         * 手动保存
         */
        onSave() {
            if(this.configParams.configName === ''){
                Message.warning('请输入图纸名称！')
                return
            }
            if(JSON.stringify(this.configObj) !== '{}' && !this.configObj.signFixedInfoStatus){
                Message.warning('您还有未完成的标记!')
                return
            }
            this.onEditElectrical().then(()=>{
                this.onSignClear('add');
            })
        },
        /**
         * 下载图片
         */
        onDownLoadImage(){
            saveImage(this.configParams.fileUrl,this.configParams.configName)
        },
        /**
         * 修改发布状态
         * @param status
         */
        onRelease(status) {
            if(status === 1){
                Modal.confirm({
                    title: '提示',
                    content: '<p>请确认是否保存完成？</p>',
                    onOk: () => {
                        if(this.electricalId === ''){
                            Message.warning('请先保存！')
                            return
                        }
                        this.onUpdateElectricStatus(status).then()
                        this.onSignClear()
                    }
                });
            }else{
                this.onUpdateElectricStatus(status).then()
            }
        },
        /**
         * 获取结构部件信息
         */
        async onStructuralIdService(id) {
            try {
                let params = {
                    id:id,
                }
                const { code,data } = await getStructuralIdService(params)
                if(code === 'SUCCESS'){
                    this.configSelectObj.partsName = data.partsName
                    this.configSelectObj.partsImageKey = data.fileUrl
                    this.onImageUrl(data.fileUrl,'thumbnail')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取电气图册信息
         */
        async onElectricalInfo() {
            try {
                this.configLoading = true
                let params = {
                    id:this.electricalId,
                }
                const { code,data } = await getElectricalInfoService(params)
                if(code === 'SUCCESS'){
                    if(data.status === 0){
                        this.onBack()
                        return
                    }
                    this.configParams.fileUrl = data.file
                    this.configParams.configName = data.fileName
                    this.configParams.fileSign = data.fileUrl
                    this.configParams.status = data.status
                    this.configArray = data
                    if(data.partsCoordinate !== ''){
                        this.configParams.configData = JSON.parse(data.partsCoordinate)
                        this.configArray.partsCoordinate = JSON.parse(data.partsCoordinate)
                    }else{
                        this.configArray.partsCoordinate = []
                    }
                    this.onImageLoad(data.file)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑电气图册
         * @returns {Promise<void>}
         */
        async onEditElectrical() {
            try {
                this.configLoading = true
                for(let i in this.configParams.configData){
                    this.configParams.configData[i].signFixedStatus = true
                    this.configParams.configData[i].signFixedInfoStatus = false
                }
                const configJson = JSON.stringify(this.configParams.configData)
                let params = {
                    id:this.electricalId,
                    equipmentId:this.deviceInfo.id,
                    electricAtlasName:this.configParams.configName,
                    fileUrl:this.configParams.fileSign,
                    partsCoordinate:configJson
                }
                const { code,data } = await editElectricalService(params)
                if(code === 'SUCCESS'){
                    this.configParams.fileUrl = data.file
                    this.configParams.configName = data.fileName
                    this.configArray = data
                    if(data.partsCoordinate !== ''){
                        this.configParams.configData = JSON.parse(data.partsCoordinate)
                        this.configArray.partsCoordinate = JSON.parse(data.partsCoordinate)
                    }else{
                        this.configArray.partsCoordinate = []
                    }
                    this.configParams.fileSign = data.fileUrl
                    this.configParams.status = data.status
                    this.SET_ELECTRICAL_ID(data.id)
                    Message.success('操作成功！')
                }else if(code === 'EQUIPMENT_ELECTRIC_ATLAS_NAME_REPEAT'){
                    Message.error('图册名称不可重复！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 根据设备id查询结构部件
         */
        async onTabList() {
            try {
                this.tabArray = []
                let params = {
                    id:this.deviceInfo.id,
                    dataType:4
                }
                const { code,data } = await getDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    this.tabArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 电气图册更新状态
         */
        async onUpdateElectricStatus(status) {
            try {
                let params = {
                    id:this.electricalId,
                    status:status
                }
                const { code } = await updateElectricStatusService(params)
                if(code === 'SUCCESS'){
                    if(status === 1 ){
                        this.configTabStatus = false
                        Message.success('发布成功！')
                    }
                    if(status === 0 ){
                        Message.success('撤回成功！')
                    }
                    this.configParams.status = status
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id,thumbnail) {
            try {
                this.systemLoading = true
                let params = {
                    resourceName:id,
                }
                const {code,data} = await queryFilesInfoService(params);
                if(code === 'SUCCESS'){
                    let suffixIndex = id.lastIndexOf('.')
                    let suffix=id.substring(suffixIndex+1).toUpperCase();
                    if(thumbnail!=='thumbnail'){
                        if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                            window.open(data.url,'_blank')
                        }else{
                            this.imageSrc = data.url
                            this.imageName = this.configSelectObj.partsName
                            this.viewImageVisible = true
                        }
                    }else{
                        if(suffix!="BMP"&&suffix!="JPG"&&suffix!="JPEG"&&suffix!="PNG"&&suffix!="GIF"){
                            this.configSelectObj.fileContent = 'video'
                        }else{
                            this.configSelectObj.fileContent = 'image'
                        }
                        this.imageName = this.configSelectObj.partsName
                        this.imageSrc = data.url
                    }
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
                this.systemLoading = false
            }catch (error) {
                this.systemLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
    }
}
export default Electrical
